/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/integratesearch/v4/integratesearch.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1CarStatus = {
    UNSPECIFIED: 'CAR_STATUS_UNSPECIFIED',
    SALE: 'CAR_STATUS_SALE',
    RESERVE: 'CAR_STATUS_RESERVE',
    TRADED: 'CAR_STATUS_TRADED'
} as const;

export type V1CarStatus = typeof V1CarStatus[keyof typeof V1CarStatus];



