/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/fleamarket/v4/fleamarket.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1KeywordType = {
    UNSPECIFIED: 'KEYWORD_TYPE_UNSPECIFIED',
    KEYWORD: 'KEYWORD_TYPE_KEYWORD',
    SHORTCUT: 'KEYWORD_TYPE_SHORTCUT'
} as const;

export type V1KeywordType = typeof V1KeywordType[keyof typeof V1KeywordType];



