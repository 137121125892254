/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/integratesearch/v4/integratesearch.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * - REALTY_STATUS_ON_SALE: 거래중  - REALTY_STATUS_RESERVED: 예약중  - REALTY_STATUS_TRADE_COMPLETED: 거래완료
 * @export
 * @enum {string}
 */

export const V1RealtyStatus = {
    UNSPECIFIED: 'REALTY_STATUS_UNSPECIFIED',
    ON_SALE: 'REALTY_STATUS_ON_SALE',
    RESERVED: 'REALTY_STATUS_RESERVED',
    TRADE_COMPLETED: 'REALTY_STATUS_TRADE_COMPLETED'
} as const;

export type V1RealtyStatus = typeof V1RealtyStatus[keyof typeof V1RealtyStatus];



