/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/fleamarket/v4/fleamarket.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1NeighborAdStatus = {
    UNSPECIFIED: 'NEIGHBOR_AD_STATUS_UNSPECIFIED',
    ONGOING: 'NEIGHBOR_AD_STATUS_ONGOING',
    RESERVED: 'NEIGHBOR_AD_STATUS_RESERVED',
    CLOSED: 'NEIGHBOR_AD_STATUS_CLOSED'
} as const;

export type V1NeighborAdStatus = typeof V1NeighborAdStatus[keyof typeof V1NeighborAdStatus];



