/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/recentsearch/v4/recentsearch.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RpcStatus } from '../models';
// @ts-ignore
import { V4BatchCreateQueryRequest } from '../models';
// @ts-ignore
import { V4DeleteQueryRequest } from '../models';
// @ts-ignore
import { V4DeleteShortcutRequest } from '../models';
// @ts-ignore
import { V4ListResponse } from '../models';
/**
 * RecentSearchServiceApi - axios parameter creator
 * @export
 */
export const RecentSearchServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {V4BatchCreateQueryRequest} v4BatchCreateQueryRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentSearchServiceBatchCreateQuery: async (v4BatchCreateQueryRequest: V4BatchCreateQueryRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'v4BatchCreateQueryRequest' is not null or undefined
            assertParamExists('recentSearchServiceBatchCreateQuery', 'v4BatchCreateQueryRequest', v4BatchCreateQueryRequest)
            const localVarPath = `/api/v4/recent-searches/queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Access-Token", configuration)

            // authentication AuthToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Auth-Token", configuration)

            if (xSearchUserId !== undefined && xSearchUserId !== null) {
                localVarHeaderParameter['X-Search-User-Id'] = String(xSearchUserId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['X-Request-Id'] = String(xRequestId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['X-Karrot-Session-Id'] = String(xKarrotSessionId);
            }

            if (xSearchTestGroup !== undefined && xSearchTestGroup !== null) {
                localVarHeaderParameter['X-Search-Test-Group'] = String(xSearchTestGroup);
            }

            if (xSearchFunnelId !== undefined && xSearchFunnelId !== null) {
                localVarHeaderParameter['X-Search-Funnel-Id'] = String(xSearchFunnelId);
            }

            if (xSearchFunnelFrom !== undefined && xSearchFunnelFrom !== null) {
                localVarHeaderParameter['X-Search-Funnel-From'] = String(xSearchFunnelFrom);
            }

            if (xSearchQueryFrom !== undefined && xSearchQueryFrom !== null) {
                localVarHeaderParameter['X-Search-Query-From'] = String(xSearchQueryFrom);
            }

            if (xSearchTab !== undefined && xSearchTab !== null) {
                localVarHeaderParameter['X-Search-Tab'] = String(xSearchTab);
            }

            if (xSearchScreenDepthName !== undefined && xSearchScreenDepthName !== null) {
                localVarHeaderParameter['X-Search-Screen-Depth-Name'] = String(xSearchScreenDepthName);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['X-User-Agent'] = String(xUserAgent);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v4BatchCreateQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentSearchServiceBatchDelete: async (body: object, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('recentSearchServiceBatchDelete', 'body', body)
            const localVarPath = `/api/v4/recent-searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Access-Token", configuration)

            // authentication AuthToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Auth-Token", configuration)

            if (xSearchUserId !== undefined && xSearchUserId !== null) {
                localVarHeaderParameter['X-Search-User-Id'] = String(xSearchUserId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['X-Request-Id'] = String(xRequestId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['X-Karrot-Session-Id'] = String(xKarrotSessionId);
            }

            if (xSearchTestGroup !== undefined && xSearchTestGroup !== null) {
                localVarHeaderParameter['X-Search-Test-Group'] = String(xSearchTestGroup);
            }

            if (xSearchFunnelId !== undefined && xSearchFunnelId !== null) {
                localVarHeaderParameter['X-Search-Funnel-Id'] = String(xSearchFunnelId);
            }

            if (xSearchFunnelFrom !== undefined && xSearchFunnelFrom !== null) {
                localVarHeaderParameter['X-Search-Funnel-From'] = String(xSearchFunnelFrom);
            }

            if (xSearchQueryFrom !== undefined && xSearchQueryFrom !== null) {
                localVarHeaderParameter['X-Search-Query-From'] = String(xSearchQueryFrom);
            }

            if (xSearchTab !== undefined && xSearchTab !== null) {
                localVarHeaderParameter['X-Search-Tab'] = String(xSearchTab);
            }

            if (xSearchScreenDepthName !== undefined && xSearchScreenDepthName !== null) {
                localVarHeaderParameter['X-Search-Screen-Depth-Name'] = String(xSearchScreenDepthName);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['X-User-Agent'] = String(xUserAgent);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {V4DeleteQueryRequest} v4DeleteQueryRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentSearchServiceDeleteQuery: async (v4DeleteQueryRequest: V4DeleteQueryRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'v4DeleteQueryRequest' is not null or undefined
            assertParamExists('recentSearchServiceDeleteQuery', 'v4DeleteQueryRequest', v4DeleteQueryRequest)
            const localVarPath = `/api/v4/recent-searches/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Access-Token", configuration)

            // authentication AuthToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Auth-Token", configuration)

            if (xSearchUserId !== undefined && xSearchUserId !== null) {
                localVarHeaderParameter['X-Search-User-Id'] = String(xSearchUserId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['X-Request-Id'] = String(xRequestId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['X-Karrot-Session-Id'] = String(xKarrotSessionId);
            }

            if (xSearchTestGroup !== undefined && xSearchTestGroup !== null) {
                localVarHeaderParameter['X-Search-Test-Group'] = String(xSearchTestGroup);
            }

            if (xSearchFunnelId !== undefined && xSearchFunnelId !== null) {
                localVarHeaderParameter['X-Search-Funnel-Id'] = String(xSearchFunnelId);
            }

            if (xSearchFunnelFrom !== undefined && xSearchFunnelFrom !== null) {
                localVarHeaderParameter['X-Search-Funnel-From'] = String(xSearchFunnelFrom);
            }

            if (xSearchQueryFrom !== undefined && xSearchQueryFrom !== null) {
                localVarHeaderParameter['X-Search-Query-From'] = String(xSearchQueryFrom);
            }

            if (xSearchTab !== undefined && xSearchTab !== null) {
                localVarHeaderParameter['X-Search-Tab'] = String(xSearchTab);
            }

            if (xSearchScreenDepthName !== undefined && xSearchScreenDepthName !== null) {
                localVarHeaderParameter['X-Search-Screen-Depth-Name'] = String(xSearchScreenDepthName);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['X-User-Agent'] = String(xUserAgent);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v4DeleteQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {V4DeleteShortcutRequest} v4DeleteShortcutRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentSearchServiceDeleteShortcut: async (v4DeleteShortcutRequest: V4DeleteShortcutRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'v4DeleteShortcutRequest' is not null or undefined
            assertParamExists('recentSearchServiceDeleteShortcut', 'v4DeleteShortcutRequest', v4DeleteShortcutRequest)
            const localVarPath = `/api/v4/recent-searches/shortcut`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Access-Token", configuration)

            // authentication AuthToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Auth-Token", configuration)

            if (xSearchUserId !== undefined && xSearchUserId !== null) {
                localVarHeaderParameter['X-Search-User-Id'] = String(xSearchUserId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['X-Request-Id'] = String(xRequestId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['X-Karrot-Session-Id'] = String(xKarrotSessionId);
            }

            if (xSearchTestGroup !== undefined && xSearchTestGroup !== null) {
                localVarHeaderParameter['X-Search-Test-Group'] = String(xSearchTestGroup);
            }

            if (xSearchFunnelId !== undefined && xSearchFunnelId !== null) {
                localVarHeaderParameter['X-Search-Funnel-Id'] = String(xSearchFunnelId);
            }

            if (xSearchFunnelFrom !== undefined && xSearchFunnelFrom !== null) {
                localVarHeaderParameter['X-Search-Funnel-From'] = String(xSearchFunnelFrom);
            }

            if (xSearchQueryFrom !== undefined && xSearchQueryFrom !== null) {
                localVarHeaderParameter['X-Search-Query-From'] = String(xSearchQueryFrom);
            }

            if (xSearchTab !== undefined && xSearchTab !== null) {
                localVarHeaderParameter['X-Search-Tab'] = String(xSearchTab);
            }

            if (xSearchScreenDepthName !== undefined && xSearchScreenDepthName !== null) {
                localVarHeaderParameter['X-Search-Screen-Depth-Name'] = String(xSearchScreenDepthName);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['X-User-Agent'] = String(xUserAgent);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v4DeleteShortcutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [regionId] 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentSearchServiceList: async (regionId?: string, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v4/recent-searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Access-Token", configuration)

            // authentication AuthToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Auth-Token", configuration)

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (xSearchUserId !== undefined && xSearchUserId !== null) {
                localVarHeaderParameter['X-Search-User-Id'] = String(xSearchUserId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['X-Request-Id'] = String(xRequestId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['X-Karrot-Session-Id'] = String(xKarrotSessionId);
            }

            if (xSearchTestGroup !== undefined && xSearchTestGroup !== null) {
                localVarHeaderParameter['X-Search-Test-Group'] = String(xSearchTestGroup);
            }

            if (xSearchFunnelId !== undefined && xSearchFunnelId !== null) {
                localVarHeaderParameter['X-Search-Funnel-Id'] = String(xSearchFunnelId);
            }

            if (xSearchFunnelFrom !== undefined && xSearchFunnelFrom !== null) {
                localVarHeaderParameter['X-Search-Funnel-From'] = String(xSearchFunnelFrom);
            }

            if (xSearchQueryFrom !== undefined && xSearchQueryFrom !== null) {
                localVarHeaderParameter['X-Search-Query-From'] = String(xSearchQueryFrom);
            }

            if (xSearchTab !== undefined && xSearchTab !== null) {
                localVarHeaderParameter['X-Search-Tab'] = String(xSearchTab);
            }

            if (xSearchScreenDepthName !== undefined && xSearchScreenDepthName !== null) {
                localVarHeaderParameter['X-Search-Screen-Depth-Name'] = String(xSearchScreenDepthName);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['X-User-Agent'] = String(xUserAgent);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecentSearchServiceApi - functional programming interface
 * @export
 */
export const RecentSearchServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecentSearchServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {V4BatchCreateQueryRequest} v4BatchCreateQueryRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recentSearchServiceBatchCreateQuery(v4BatchCreateQueryRequest: V4BatchCreateQueryRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recentSearchServiceBatchCreateQuery(v4BatchCreateQueryRequest, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recentSearchServiceBatchDelete(body: object, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recentSearchServiceBatchDelete(body, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {V4DeleteQueryRequest} v4DeleteQueryRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recentSearchServiceDeleteQuery(v4DeleteQueryRequest: V4DeleteQueryRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recentSearchServiceDeleteQuery(v4DeleteQueryRequest, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {V4DeleteShortcutRequest} v4DeleteShortcutRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recentSearchServiceDeleteShortcut(v4DeleteShortcutRequest: V4DeleteShortcutRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recentSearchServiceDeleteShortcut(v4DeleteShortcutRequest, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [regionId] 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recentSearchServiceList(regionId?: string, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V4ListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recentSearchServiceList(regionId, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecentSearchServiceApi - factory interface
 * @export
 */
export const RecentSearchServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecentSearchServiceApiFp(configuration)
    return {
        /**
         * 
         * @param {V4BatchCreateQueryRequest} v4BatchCreateQueryRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentSearchServiceBatchCreateQuery(v4BatchCreateQueryRequest: V4BatchCreateQueryRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: any): AxiosPromise<object> {
            return localVarFp.recentSearchServiceBatchCreateQuery(v4BatchCreateQueryRequest, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentSearchServiceBatchDelete(body: object, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: any): AxiosPromise<object> {
            return localVarFp.recentSearchServiceBatchDelete(body, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V4DeleteQueryRequest} v4DeleteQueryRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentSearchServiceDeleteQuery(v4DeleteQueryRequest: V4DeleteQueryRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: any): AxiosPromise<object> {
            return localVarFp.recentSearchServiceDeleteQuery(v4DeleteQueryRequest, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V4DeleteShortcutRequest} v4DeleteShortcutRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentSearchServiceDeleteShortcut(v4DeleteShortcutRequest: V4DeleteShortcutRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: any): AxiosPromise<object> {
            return localVarFp.recentSearchServiceDeleteShortcut(v4DeleteShortcutRequest, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [regionId] 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentSearchServiceList(regionId?: string, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: any): AxiosPromise<V4ListResponse> {
            return localVarFp.recentSearchServiceList(regionId, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecentSearchServiceApi - object-oriented interface
 * @export
 * @class RecentSearchServiceApi
 * @extends {BaseAPI}
 */
export class RecentSearchServiceApi extends BaseAPI {
    /**
     * 
     * @param {V4BatchCreateQueryRequest} v4BatchCreateQueryRequest 
     * @param {string} [xSearchUserId] user id
     * @param {string} [xRequestId] request id
     * @param {string} [xKarrotSessionId] karrot session id
     * @param {string} [xSearchTestGroup] search test group
     * @param {string} [xSearchFunnelId] search funnel id
     * @param {string} [xSearchFunnelFrom] search funnel from
     * @param {string} [xSearchQueryFrom] search query from
     * @param {string} [xSearchTab] search tab
     * @param {string} [xSearchScreenDepthName] search screen depth name
     * @param {string} [xUserAgent] (deprecated) user agent
     * @param {string} [xForwardedFor] (deprecated) forwared for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchServiceApi
     */
    public recentSearchServiceBatchCreateQuery(v4BatchCreateQueryRequest: V4BatchCreateQueryRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig) {
        return RecentSearchServiceApiFp(this.configuration).recentSearchServiceBatchCreateQuery(v4BatchCreateQueryRequest, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {string} [xSearchUserId] user id
     * @param {string} [xRequestId] request id
     * @param {string} [xKarrotSessionId] karrot session id
     * @param {string} [xSearchTestGroup] search test group
     * @param {string} [xSearchFunnelId] search funnel id
     * @param {string} [xSearchFunnelFrom] search funnel from
     * @param {string} [xSearchQueryFrom] search query from
     * @param {string} [xSearchTab] search tab
     * @param {string} [xSearchScreenDepthName] search screen depth name
     * @param {string} [xUserAgent] (deprecated) user agent
     * @param {string} [xForwardedFor] (deprecated) forwared for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchServiceApi
     */
    public recentSearchServiceBatchDelete(body: object, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig) {
        return RecentSearchServiceApiFp(this.configuration).recentSearchServiceBatchDelete(body, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V4DeleteQueryRequest} v4DeleteQueryRequest 
     * @param {string} [xSearchUserId] user id
     * @param {string} [xRequestId] request id
     * @param {string} [xKarrotSessionId] karrot session id
     * @param {string} [xSearchTestGroup] search test group
     * @param {string} [xSearchFunnelId] search funnel id
     * @param {string} [xSearchFunnelFrom] search funnel from
     * @param {string} [xSearchQueryFrom] search query from
     * @param {string} [xSearchTab] search tab
     * @param {string} [xSearchScreenDepthName] search screen depth name
     * @param {string} [xUserAgent] (deprecated) user agent
     * @param {string} [xForwardedFor] (deprecated) forwared for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchServiceApi
     */
    public recentSearchServiceDeleteQuery(v4DeleteQueryRequest: V4DeleteQueryRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig) {
        return RecentSearchServiceApiFp(this.configuration).recentSearchServiceDeleteQuery(v4DeleteQueryRequest, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V4DeleteShortcutRequest} v4DeleteShortcutRequest 
     * @param {string} [xSearchUserId] user id
     * @param {string} [xRequestId] request id
     * @param {string} [xKarrotSessionId] karrot session id
     * @param {string} [xSearchTestGroup] search test group
     * @param {string} [xSearchFunnelId] search funnel id
     * @param {string} [xSearchFunnelFrom] search funnel from
     * @param {string} [xSearchQueryFrom] search query from
     * @param {string} [xSearchTab] search tab
     * @param {string} [xSearchScreenDepthName] search screen depth name
     * @param {string} [xUserAgent] (deprecated) user agent
     * @param {string} [xForwardedFor] (deprecated) forwared for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchServiceApi
     */
    public recentSearchServiceDeleteShortcut(v4DeleteShortcutRequest: V4DeleteShortcutRequest, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig) {
        return RecentSearchServiceApiFp(this.configuration).recentSearchServiceDeleteShortcut(v4DeleteShortcutRequest, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [regionId] 
     * @param {string} [xSearchUserId] user id
     * @param {string} [xRequestId] request id
     * @param {string} [xKarrotSessionId] karrot session id
     * @param {string} [xSearchTestGroup] search test group
     * @param {string} [xSearchFunnelId] search funnel id
     * @param {string} [xSearchFunnelFrom] search funnel from
     * @param {string} [xSearchQueryFrom] search query from
     * @param {string} [xSearchTab] search tab
     * @param {string} [xSearchScreenDepthName] search screen depth name
     * @param {string} [xUserAgent] (deprecated) user agent
     * @param {string} [xForwardedFor] (deprecated) forwared for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchServiceApi
     */
    public recentSearchServiceList(regionId?: string, xSearchUserId?: string, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig) {
        return RecentSearchServiceApiFp(this.configuration).recentSearchServiceList(regionId, xSearchUserId, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xUserAgent, xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }
}
