/* tslint:disable */
/* eslint-disable */
/**
 * Test swagger
 * Testing the Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiV1PropertyFleaMarketPriceFreeTextsGet200Response } from '../models';
// @ts-ignore
import { ApiV1PropertyFleaMarketPriceFreeTextsGet5XXResponse } from '../models';
/**
 * PropertyApi - axios parameter creator
 * @export
 */
export const PropertyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 중고거래 텍스트 문구 변경
         * @summary 중고거래 텍스트 문구 변경
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PropertyFleaMarketPriceFreeTextsGet: async (xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/property/flea-market-price-free-texts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xAuthKarrotUserId !== undefined && xAuthKarrotUserId !== null) {
                localVarHeaderParameter['x-auth-karrot-user-id'] = String(xAuthKarrotUserId);
            }

            if (xAuthType !== undefined && xAuthType !== null) {
                localVarHeaderParameter['x-auth-type'] = String(xAuthType);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyApi - functional programming interface
 * @export
 */
export const PropertyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyApiAxiosParamCreator(configuration)
    return {
        /**
         * 중고거래 텍스트 문구 변경
         * @summary 중고거래 텍스트 문구 변경
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PropertyFleaMarketPriceFreeTextsGet(xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1PropertyFleaMarketPriceFreeTextsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PropertyFleaMarketPriceFreeTextsGet(xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyApi - factory interface
 * @export
 */
export const PropertyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyApiFp(configuration)
    return {
        /**
         * 중고거래 텍스트 문구 변경
         * @summary 중고거래 텍스트 문구 변경
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PropertyFleaMarketPriceFreeTextsGet(xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, options?: any): AxiosPromise<ApiV1PropertyFleaMarketPriceFreeTextsGet200Response> {
            return localVarFp.apiV1PropertyFleaMarketPriceFreeTextsGet(xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1PropertyFleaMarketPriceFreeTextsGet operation in PropertyApi.
 * @export
 * @interface PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGetRequest
 */
export interface PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGetRequest {
    /**
     * x-auth-token
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xKarrotSessionId?: string

    /**
     * x-auth-karrot-user-id
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xAuthKarrotUserId?: string

    /**
     * x-auth-type
     * @type {string}
     * @memberof PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGet
     */
    readonly xAuthType?: string
}

/**
 * PropertyApi - object-oriented interface
 * @export
 * @class PropertyApi
 * @extends {BaseAPI}
 */
export class PropertyApi extends BaseAPI {
    /**
     * 중고거래 텍스트 문구 변경
     * @summary 중고거래 텍스트 문구 변경
     * @param {PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public apiV1PropertyFleaMarketPriceFreeTextsGet(requestParameters: PropertyApiApiV1PropertyFleaMarketPriceFreeTextsGetRequest = {}, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).apiV1PropertyFleaMarketPriceFreeTextsGet(requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xAuthKarrotUserId, requestParameters.xAuthType, options).then((request) => request(this.axios, this.basePath));
    }
}
