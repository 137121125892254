/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/fleamarket/v4/fleamarket.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1RelatedKeywordsTemplate = {
    UNSPECIFIED: 'RELATED_KEYWORDS_TEMPLATE_UNSPECIFIED',
    TOP: 'RELATED_KEYWORDS_TEMPLATE_TOP',
    FILTER: 'RELATED_KEYWORDS_TEMPLATE_FILTER',
    LIST_VIEW: 'RELATED_KEYWORDS_TEMPLATE_LIST_VIEW'
} as const;

export type V1RelatedKeywordsTemplate = typeof V1RelatedKeywordsTemplate[keyof typeof V1RelatedKeywordsTemplate];



