/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/place/v4/place.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1OperationStatus = {
    UNSPECIFIED: 'OPERATION_STATUS_UNSPECIFIED',
    OPEN: 'OPERATION_STATUS_OPEN',
    CLOSED_FOR_PREPARATION: 'OPERATION_STATUS_CLOSED_FOR_PREPARATION',
    BREAK_TIME: 'OPERATION_STATUS_BREAK_TIME',
    CLOSED_TODAY: 'OPERATION_STATUS_CLOSED_TODAY',
    OPEN_24_HOURS: 'OPERATION_STATUS_OPEN_24_HOURS'
} as const;

export type V1OperationStatus = typeof V1OperationStatus[keyof typeof V1OperationStatus];



