/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/recentsearch/v4/recentsearch.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1RecentSearchType = {
    UNSPECIFIED: 'RECENT_SEARCH_TYPE_UNSPECIFIED',
    QUERY: 'RECENT_SEARCH_TYPE_QUERY',
    SHORTCUT: 'RECENT_SEARCH_TYPE_SHORTCUT'
} as const;

export type V1RecentSearchType = typeof V1RecentSearchType[keyof typeof V1RecentSearchType];



