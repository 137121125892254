/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/integratesearch/v4/integratesearch.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1CarOptionName = {
    UNSPECIFIED: 'CAR_OPTION_NAME_UNSPECIFIED',
    SMOKING_FREE: 'CAR_OPTION_NAME_SMOKING_FREE'
} as const;

export type V1CarOptionName = typeof V1CarOptionName[keyof typeof V1CarOptionName];



