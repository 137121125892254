/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/integratesearch/v4/integratesearch.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1JobSalaryType = {
    UNSPECIFIED: 'JOB_SALARY_TYPE_UNSPECIFIED',
    DAILY: 'JOB_SALARY_TYPE_DAILY',
    HOURLY: 'JOB_SALARY_TYPE_HOURLY',
    MONTHLY: 'JOB_SALARY_TYPE_MONTHLY',
    PER_CASE: 'JOB_SALARY_TYPE_PER_CASE'
} as const;

export type V1JobSalaryType = typeof V1JobSalaryType[keyof typeof V1JobSalaryType];



