/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/fleamarket/v4/fleamarket.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RpcStatus } from '../models';
// @ts-ignore
import { V1ErrorDetail } from '../models';
// @ts-ignore
import { V4GetFilterResponse } from '../models';
// @ts-ignore
import { V4ListRelatedKeywordsResponse } from '../models';
// @ts-ignore
import { V4SearchRequest } from '../models';
// @ts-ignore
import { V4SearchResponse } from '../models';
/**
 * FleaMarketServiceApi - axios parameter creator
 * @export
 */
export const FleaMarketServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} keyword 
         * @param {string} [regionId] 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xSearchQueryId] query id
         * @param {string} [xSearchRequestId] search request id
         * @param {string} [xRequestId] request id
         * @param {string} [xSearchSessionExpiredTime] session expired time
         * @param {string} [xSearchSessionId] search session id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchPreviousQuery] search previous query
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xSearchReleaseContents] search release contents
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fleaMarketServiceGetFilter: async (keyword: string, regionId?: string, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('fleaMarketServiceGetFilter', 'keyword', keyword)
            const localVarPath = `/api/v4/fleamarket-articles/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Access-Token", configuration)

            // authentication AuthToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Auth-Token", configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (xSearchUserId !== undefined && xSearchUserId !== null) {
                localVarHeaderParameter['X-Search-User-Id'] = String(xSearchUserId);
            }

            if (xSearchQueryId !== undefined && xSearchQueryId !== null) {
                localVarHeaderParameter['X-Search-Query-Id'] = String(xSearchQueryId);
            }

            if (xSearchRequestId !== undefined && xSearchRequestId !== null) {
                localVarHeaderParameter['X-Search-Request-Id'] = String(xSearchRequestId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['X-Request-Id'] = String(xRequestId);
            }

            if (xSearchSessionExpiredTime !== undefined && xSearchSessionExpiredTime !== null) {
                localVarHeaderParameter['X-Search-Session-Expired-Time'] = String(xSearchSessionExpiredTime);
            }

            if (xSearchSessionId !== undefined && xSearchSessionId !== null) {
                localVarHeaderParameter['X-Search-Session-Id'] = String(xSearchSessionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['X-Karrot-Session-Id'] = String(xKarrotSessionId);
            }

            if (xSearchTestGroup !== undefined && xSearchTestGroup !== null) {
                localVarHeaderParameter['X-Search-Test-Group'] = String(xSearchTestGroup);
            }

            if (xSearchPreviousQuery !== undefined && xSearchPreviousQuery !== null) {
                localVarHeaderParameter['X-Search-Previous-Query'] = String(xSearchPreviousQuery);
            }

            if (xSearchFunnelId !== undefined && xSearchFunnelId !== null) {
                localVarHeaderParameter['X-Search-Funnel-Id'] = String(xSearchFunnelId);
            }

            if (xSearchFunnelFrom !== undefined && xSearchFunnelFrom !== null) {
                localVarHeaderParameter['X-Search-Funnel-From'] = String(xSearchFunnelFrom);
            }

            if (xSearchQueryFrom !== undefined && xSearchQueryFrom !== null) {
                localVarHeaderParameter['X-Search-Query-From'] = String(xSearchQueryFrom);
            }

            if (xSearchTab !== undefined && xSearchTab !== null) {
                localVarHeaderParameter['X-Search-Tab'] = String(xSearchTab);
            }

            if (xSearchScreenDepthName !== undefined && xSearchScreenDepthName !== null) {
                localVarHeaderParameter['X-Search-Screen-Depth-Name'] = String(xSearchScreenDepthName);
            }

            if (xSearchReleaseContents !== undefined && xSearchReleaseContents !== null) {
                localVarHeaderParameter['X-Search-Release-Contents'] = String(xSearchReleaseContents);
            }

            if (xSearchOrigin !== undefined && xSearchOrigin !== null) {
                localVarHeaderParameter['X-Search-Origin'] = String(xSearchOrigin);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['X-User-Agent'] = String(xUserAgent);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} keyword 
         * @param {string} regionId 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xSearchQueryId] query id
         * @param {string} [xSearchRequestId] search request id
         * @param {string} [xRequestId] request id
         * @param {string} [xSearchSessionExpiredTime] session expired time
         * @param {string} [xSearchSessionId] search session id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchPreviousQuery] search previous query
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xSearchReleaseContents] search release contents
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fleaMarketServiceListRelatedKeywords: async (keyword: string, regionId: string, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('fleaMarketServiceListRelatedKeywords', 'keyword', keyword)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('fleaMarketServiceListRelatedKeywords', 'regionId', regionId)
            const localVarPath = `/api/v4/fleamarket-articles/related-keywords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Access-Token", configuration)

            // authentication AuthToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Auth-Token", configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (xSearchUserId !== undefined && xSearchUserId !== null) {
                localVarHeaderParameter['X-Search-User-Id'] = String(xSearchUserId);
            }

            if (xSearchQueryId !== undefined && xSearchQueryId !== null) {
                localVarHeaderParameter['X-Search-Query-Id'] = String(xSearchQueryId);
            }

            if (xSearchRequestId !== undefined && xSearchRequestId !== null) {
                localVarHeaderParameter['X-Search-Request-Id'] = String(xSearchRequestId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['X-Request-Id'] = String(xRequestId);
            }

            if (xSearchSessionExpiredTime !== undefined && xSearchSessionExpiredTime !== null) {
                localVarHeaderParameter['X-Search-Session-Expired-Time'] = String(xSearchSessionExpiredTime);
            }

            if (xSearchSessionId !== undefined && xSearchSessionId !== null) {
                localVarHeaderParameter['X-Search-Session-Id'] = String(xSearchSessionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['X-Karrot-Session-Id'] = String(xKarrotSessionId);
            }

            if (xSearchTestGroup !== undefined && xSearchTestGroup !== null) {
                localVarHeaderParameter['X-Search-Test-Group'] = String(xSearchTestGroup);
            }

            if (xSearchPreviousQuery !== undefined && xSearchPreviousQuery !== null) {
                localVarHeaderParameter['X-Search-Previous-Query'] = String(xSearchPreviousQuery);
            }

            if (xSearchFunnelId !== undefined && xSearchFunnelId !== null) {
                localVarHeaderParameter['X-Search-Funnel-Id'] = String(xSearchFunnelId);
            }

            if (xSearchFunnelFrom !== undefined && xSearchFunnelFrom !== null) {
                localVarHeaderParameter['X-Search-Funnel-From'] = String(xSearchFunnelFrom);
            }

            if (xSearchQueryFrom !== undefined && xSearchQueryFrom !== null) {
                localVarHeaderParameter['X-Search-Query-From'] = String(xSearchQueryFrom);
            }

            if (xSearchTab !== undefined && xSearchTab !== null) {
                localVarHeaderParameter['X-Search-Tab'] = String(xSearchTab);
            }

            if (xSearchScreenDepthName !== undefined && xSearchScreenDepthName !== null) {
                localVarHeaderParameter['X-Search-Screen-Depth-Name'] = String(xSearchScreenDepthName);
            }

            if (xSearchReleaseContents !== undefined && xSearchReleaseContents !== null) {
                localVarHeaderParameter['X-Search-Release-Contents'] = String(xSearchReleaseContents);
            }

            if (xSearchOrigin !== undefined && xSearchOrigin !== null) {
                localVarHeaderParameter['X-Search-Origin'] = String(xSearchOrigin);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['X-User-Agent'] = String(xUserAgent);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 중고거래 문서 검색
         * @param {V4SearchRequest} v4SearchRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xSearchQueryId] query id
         * @param {string} [xSearchRequestId] search request id
         * @param {string} [xRequestId] request id
         * @param {string} [xSearchSessionExpiredTime] session expired time
         * @param {string} [xSearchSessionId] search session id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchPreviousQuery] search previous query
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xSearchReleaseContents] search release contents
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fleaMarketServiceSearch: async (v4SearchRequest: V4SearchRequest, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'v4SearchRequest' is not null or undefined
            assertParamExists('fleaMarketServiceSearch', 'v4SearchRequest', v4SearchRequest)
            const localVarPath = `/api/v4/fleamarket/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Access-Token", configuration)

            // authentication AuthToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Auth-Token", configuration)

            if (xSearchUserId !== undefined && xSearchUserId !== null) {
                localVarHeaderParameter['X-Search-User-Id'] = String(xSearchUserId);
            }

            if (xSearchQueryId !== undefined && xSearchQueryId !== null) {
                localVarHeaderParameter['X-Search-Query-Id'] = String(xSearchQueryId);
            }

            if (xSearchRequestId !== undefined && xSearchRequestId !== null) {
                localVarHeaderParameter['X-Search-Request-Id'] = String(xSearchRequestId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['X-Request-Id'] = String(xRequestId);
            }

            if (xSearchSessionExpiredTime !== undefined && xSearchSessionExpiredTime !== null) {
                localVarHeaderParameter['X-Search-Session-Expired-Time'] = String(xSearchSessionExpiredTime);
            }

            if (xSearchSessionId !== undefined && xSearchSessionId !== null) {
                localVarHeaderParameter['X-Search-Session-Id'] = String(xSearchSessionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['X-Karrot-Session-Id'] = String(xKarrotSessionId);
            }

            if (xSearchTestGroup !== undefined && xSearchTestGroup !== null) {
                localVarHeaderParameter['X-Search-Test-Group'] = String(xSearchTestGroup);
            }

            if (xSearchPreviousQuery !== undefined && xSearchPreviousQuery !== null) {
                localVarHeaderParameter['X-Search-Previous-Query'] = String(xSearchPreviousQuery);
            }

            if (xSearchFunnelId !== undefined && xSearchFunnelId !== null) {
                localVarHeaderParameter['X-Search-Funnel-Id'] = String(xSearchFunnelId);
            }

            if (xSearchFunnelFrom !== undefined && xSearchFunnelFrom !== null) {
                localVarHeaderParameter['X-Search-Funnel-From'] = String(xSearchFunnelFrom);
            }

            if (xSearchQueryFrom !== undefined && xSearchQueryFrom !== null) {
                localVarHeaderParameter['X-Search-Query-From'] = String(xSearchQueryFrom);
            }

            if (xSearchTab !== undefined && xSearchTab !== null) {
                localVarHeaderParameter['X-Search-Tab'] = String(xSearchTab);
            }

            if (xSearchScreenDepthName !== undefined && xSearchScreenDepthName !== null) {
                localVarHeaderParameter['X-Search-Screen-Depth-Name'] = String(xSearchScreenDepthName);
            }

            if (xSearchReleaseContents !== undefined && xSearchReleaseContents !== null) {
                localVarHeaderParameter['X-Search-Release-Contents'] = String(xSearchReleaseContents);
            }

            if (xSearchOrigin !== undefined && xSearchOrigin !== null) {
                localVarHeaderParameter['X-Search-Origin'] = String(xSearchOrigin);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['X-User-Agent'] = String(xUserAgent);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v4SearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FleaMarketServiceApi - functional programming interface
 * @export
 */
export const FleaMarketServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FleaMarketServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} keyword 
         * @param {string} [regionId] 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xSearchQueryId] query id
         * @param {string} [xSearchRequestId] search request id
         * @param {string} [xRequestId] request id
         * @param {string} [xSearchSessionExpiredTime] session expired time
         * @param {string} [xSearchSessionId] search session id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchPreviousQuery] search previous query
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xSearchReleaseContents] search release contents
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fleaMarketServiceGetFilter(keyword: string, regionId?: string, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V4GetFilterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fleaMarketServiceGetFilter(keyword, regionId, xSearchUserId, xSearchQueryId, xSearchRequestId, xRequestId, xSearchSessionExpiredTime, xSearchSessionId, xKarrotSessionId, xSearchTestGroup, xSearchPreviousQuery, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xSearchReleaseContents, xSearchOrigin, xUserAgent, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} keyword 
         * @param {string} regionId 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xSearchQueryId] query id
         * @param {string} [xSearchRequestId] search request id
         * @param {string} [xRequestId] request id
         * @param {string} [xSearchSessionExpiredTime] session expired time
         * @param {string} [xSearchSessionId] search session id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchPreviousQuery] search previous query
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xSearchReleaseContents] search release contents
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fleaMarketServiceListRelatedKeywords(keyword: string, regionId: string, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V4ListRelatedKeywordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fleaMarketServiceListRelatedKeywords(keyword, regionId, xSearchUserId, xSearchQueryId, xSearchRequestId, xRequestId, xSearchSessionExpiredTime, xSearchSessionId, xKarrotSessionId, xSearchTestGroup, xSearchPreviousQuery, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xSearchReleaseContents, xSearchOrigin, xUserAgent, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 중고거래 문서 검색
         * @param {V4SearchRequest} v4SearchRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xSearchQueryId] query id
         * @param {string} [xSearchRequestId] search request id
         * @param {string} [xRequestId] request id
         * @param {string} [xSearchSessionExpiredTime] session expired time
         * @param {string} [xSearchSessionId] search session id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchPreviousQuery] search previous query
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xSearchReleaseContents] search release contents
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fleaMarketServiceSearch(v4SearchRequest: V4SearchRequest, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V4SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fleaMarketServiceSearch(v4SearchRequest, xSearchUserId, xSearchQueryId, xSearchRequestId, xRequestId, xSearchSessionExpiredTime, xSearchSessionId, xKarrotSessionId, xSearchTestGroup, xSearchPreviousQuery, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xSearchReleaseContents, xSearchOrigin, xUserAgent, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FleaMarketServiceApi - factory interface
 * @export
 */
export const FleaMarketServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FleaMarketServiceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} keyword 
         * @param {string} [regionId] 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xSearchQueryId] query id
         * @param {string} [xSearchRequestId] search request id
         * @param {string} [xRequestId] request id
         * @param {string} [xSearchSessionExpiredTime] session expired time
         * @param {string} [xSearchSessionId] search session id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchPreviousQuery] search previous query
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xSearchReleaseContents] search release contents
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fleaMarketServiceGetFilter(keyword: string, regionId?: string, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options?: any): AxiosPromise<V4GetFilterResponse> {
            return localVarFp.fleaMarketServiceGetFilter(keyword, regionId, xSearchUserId, xSearchQueryId, xSearchRequestId, xRequestId, xSearchSessionExpiredTime, xSearchSessionId, xKarrotSessionId, xSearchTestGroup, xSearchPreviousQuery, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xSearchReleaseContents, xSearchOrigin, xUserAgent, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} keyword 
         * @param {string} regionId 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xSearchQueryId] query id
         * @param {string} [xSearchRequestId] search request id
         * @param {string} [xRequestId] request id
         * @param {string} [xSearchSessionExpiredTime] session expired time
         * @param {string} [xSearchSessionId] search session id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchPreviousQuery] search previous query
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xSearchReleaseContents] search release contents
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fleaMarketServiceListRelatedKeywords(keyword: string, regionId: string, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options?: any): AxiosPromise<V4ListRelatedKeywordsResponse> {
            return localVarFp.fleaMarketServiceListRelatedKeywords(keyword, regionId, xSearchUserId, xSearchQueryId, xSearchRequestId, xRequestId, xSearchSessionExpiredTime, xSearchSessionId, xKarrotSessionId, xSearchTestGroup, xSearchPreviousQuery, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xSearchReleaseContents, xSearchOrigin, xUserAgent, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 중고거래 문서 검색
         * @param {V4SearchRequest} v4SearchRequest 
         * @param {string} [xSearchUserId] user id
         * @param {string} [xSearchQueryId] query id
         * @param {string} [xSearchRequestId] search request id
         * @param {string} [xRequestId] request id
         * @param {string} [xSearchSessionExpiredTime] session expired time
         * @param {string} [xSearchSessionId] search session id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchPreviousQuery] search previous query
         * @param {string} [xSearchFunnelId] search funnel id
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchQueryFrom] search query from
         * @param {string} [xSearchTab] search tab
         * @param {string} [xSearchScreenDepthName] search screen depth name
         * @param {string} [xSearchReleaseContents] search release contents
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {string} [xForwardedFor] (deprecated) forwared for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fleaMarketServiceSearch(v4SearchRequest: V4SearchRequest, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options?: any): AxiosPromise<V4SearchResponse> {
            return localVarFp.fleaMarketServiceSearch(v4SearchRequest, xSearchUserId, xSearchQueryId, xSearchRequestId, xRequestId, xSearchSessionExpiredTime, xSearchSessionId, xKarrotSessionId, xSearchTestGroup, xSearchPreviousQuery, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xSearchReleaseContents, xSearchOrigin, xUserAgent, xForwardedFor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FleaMarketServiceApi - object-oriented interface
 * @export
 * @class FleaMarketServiceApi
 * @extends {BaseAPI}
 */
export class FleaMarketServiceApi extends BaseAPI {
    /**
     * 
     * @param {string} keyword 
     * @param {string} [regionId] 
     * @param {string} [xSearchUserId] user id
     * @param {string} [xSearchQueryId] query id
     * @param {string} [xSearchRequestId] search request id
     * @param {string} [xRequestId] request id
     * @param {string} [xSearchSessionExpiredTime] session expired time
     * @param {string} [xSearchSessionId] search session id
     * @param {string} [xKarrotSessionId] karrot session id
     * @param {string} [xSearchTestGroup] search test group
     * @param {string} [xSearchPreviousQuery] search previous query
     * @param {string} [xSearchFunnelId] search funnel id
     * @param {string} [xSearchFunnelFrom] search funnel from
     * @param {string} [xSearchQueryFrom] search query from
     * @param {string} [xSearchTab] search tab
     * @param {string} [xSearchScreenDepthName] search screen depth name
     * @param {string} [xSearchReleaseContents] search release contents
     * @param {string} [xSearchOrigin] search origin
     * @param {string} [xUserAgent] (deprecated) user agent
     * @param {string} [xForwardedFor] (deprecated) forwared for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleaMarketServiceApi
     */
    public fleaMarketServiceGetFilter(keyword: string, regionId?: string, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig) {
        return FleaMarketServiceApiFp(this.configuration).fleaMarketServiceGetFilter(keyword, regionId, xSearchUserId, xSearchQueryId, xSearchRequestId, xRequestId, xSearchSessionExpiredTime, xSearchSessionId, xKarrotSessionId, xSearchTestGroup, xSearchPreviousQuery, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xSearchReleaseContents, xSearchOrigin, xUserAgent, xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} keyword 
     * @param {string} regionId 
     * @param {string} [xSearchUserId] user id
     * @param {string} [xSearchQueryId] query id
     * @param {string} [xSearchRequestId] search request id
     * @param {string} [xRequestId] request id
     * @param {string} [xSearchSessionExpiredTime] session expired time
     * @param {string} [xSearchSessionId] search session id
     * @param {string} [xKarrotSessionId] karrot session id
     * @param {string} [xSearchTestGroup] search test group
     * @param {string} [xSearchPreviousQuery] search previous query
     * @param {string} [xSearchFunnelId] search funnel id
     * @param {string} [xSearchFunnelFrom] search funnel from
     * @param {string} [xSearchQueryFrom] search query from
     * @param {string} [xSearchTab] search tab
     * @param {string} [xSearchScreenDepthName] search screen depth name
     * @param {string} [xSearchReleaseContents] search release contents
     * @param {string} [xSearchOrigin] search origin
     * @param {string} [xUserAgent] (deprecated) user agent
     * @param {string} [xForwardedFor] (deprecated) forwared for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleaMarketServiceApi
     */
    public fleaMarketServiceListRelatedKeywords(keyword: string, regionId: string, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig) {
        return FleaMarketServiceApiFp(this.configuration).fleaMarketServiceListRelatedKeywords(keyword, regionId, xSearchUserId, xSearchQueryId, xSearchRequestId, xRequestId, xSearchSessionExpiredTime, xSearchSessionId, xKarrotSessionId, xSearchTestGroup, xSearchPreviousQuery, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xSearchReleaseContents, xSearchOrigin, xUserAgent, xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 중고거래 문서 검색
     * @param {V4SearchRequest} v4SearchRequest 
     * @param {string} [xSearchUserId] user id
     * @param {string} [xSearchQueryId] query id
     * @param {string} [xSearchRequestId] search request id
     * @param {string} [xRequestId] request id
     * @param {string} [xSearchSessionExpiredTime] session expired time
     * @param {string} [xSearchSessionId] search session id
     * @param {string} [xKarrotSessionId] karrot session id
     * @param {string} [xSearchTestGroup] search test group
     * @param {string} [xSearchPreviousQuery] search previous query
     * @param {string} [xSearchFunnelId] search funnel id
     * @param {string} [xSearchFunnelFrom] search funnel from
     * @param {string} [xSearchQueryFrom] search query from
     * @param {string} [xSearchTab] search tab
     * @param {string} [xSearchScreenDepthName] search screen depth name
     * @param {string} [xSearchReleaseContents] search release contents
     * @param {string} [xSearchOrigin] search origin
     * @param {string} [xUserAgent] (deprecated) user agent
     * @param {string} [xForwardedFor] (deprecated) forwared for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleaMarketServiceApi
     */
    public fleaMarketServiceSearch(v4SearchRequest: V4SearchRequest, xSearchUserId?: string, xSearchQueryId?: string, xSearchRequestId?: string, xRequestId?: string, xSearchSessionExpiredTime?: string, xSearchSessionId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchPreviousQuery?: string, xSearchFunnelId?: string, xSearchFunnelFrom?: string, xSearchQueryFrom?: string, xSearchTab?: string, xSearchScreenDepthName?: string, xSearchReleaseContents?: string, xSearchOrigin?: string, xUserAgent?: string, xForwardedFor?: string, options?: AxiosRequestConfig) {
        return FleaMarketServiceApiFp(this.configuration).fleaMarketServiceSearch(v4SearchRequest, xSearchUserId, xSearchQueryId, xSearchRequestId, xRequestId, xSearchSessionExpiredTime, xSearchSessionId, xKarrotSessionId, xSearchTestGroup, xSearchPreviousQuery, xSearchFunnelId, xSearchFunnelFrom, xSearchQueryFrom, xSearchTab, xSearchScreenDepthName, xSearchReleaseContents, xSearchOrigin, xUserAgent, xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }
}
