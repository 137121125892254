/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/place/v4/place.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1PlaceComponentType = {
    UNSPECIFIED: 'PLACE_COMPONENT_TYPE_UNSPECIFIED',
    PLACE: 'PLACE_COMPONENT_TYPE_PLACE',
    BANNER: 'PLACE_COMPONENT_TYPE_BANNER'
} as const;

export type V1PlaceComponentType = typeof V1PlaceComponentType[keyof typeof V1PlaceComponentType];



