/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/place/v4/place.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1DistanceUnit = {
    UNSPECIFIED: 'DISTANCE_UNIT_UNSPECIFIED',
    METER: 'DISTANCE_UNIT_METER',
    KILOMETER: 'DISTANCE_UNIT_KILOMETER'
} as const;

export type V1DistanceUnit = typeof V1DistanceUnit[keyof typeof V1DistanceUnit];



