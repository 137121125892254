/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/fleamarket/v4/fleamarket.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1RegionRange = {
    UNSPECIFIED: 'REGION_RANGE_UNSPECIFIED',
    NO_PUBLISH: 'REGION_RANGE_NO_PUBLISH',
    MY: 'REGION_RANGE_MY',
    ADJACENT: 'REGION_RANGE_ADJACENT',
    RANGE2: 'REGION_RANGE_RANGE2',
    RANGE3: 'REGION_RANGE_RANGE3'
} as const;

export type V1RegionRange = typeof V1RegionRange[keyof typeof V1RegionRange];



