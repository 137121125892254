/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/fleamarket/v4/fleamarket.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1ErrorType = {
    UNSPECIFIED: 'ERROR_TYPE_UNSPECIFIED',
    LONG_TAIL_QUERY: 'ERROR_TYPE_LONG_TAIL_QUERY',
    NULL_QUERY: 'ERROR_TYPE_NULL_QUERY',
    UNAUTHENTICATED: 'ERROR_TYPE_UNAUTHENTICATED',
    ABUSER_DETECTED: 'ERROR_TYPE_ABUSER_DETECTED'
} as const;

export type V1ErrorType = typeof V1ErrorType[keyof typeof V1ErrorType];



