/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/integratesearch/v4/integratesearch.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1JobWorkDay = {
    UNSPECIFIED: 'JOB_WORK_DAY_UNSPECIFIED',
    MON: 'JOB_WORK_DAY_MON',
    TUE: 'JOB_WORK_DAY_TUE',
    WED: 'JOB_WORK_DAY_WED',
    THU: 'JOB_WORK_DAY_THU',
    FRI: 'JOB_WORK_DAY_FRI',
    SAT: 'JOB_WORK_DAY_SAT',
    SUN: 'JOB_WORK_DAY_SUN'
} as const;

export type V1JobWorkDay = typeof V1JobWorkDay[keyof typeof V1JobWorkDay];



