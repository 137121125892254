/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/fleamarket/v4/fleamarket.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * - OVERFLOW_ACTION_TYPE_POST: url 주소로 post 전송  - OVERFLOW_ACTION_TYPE_TARGET: url(앱스킴 or 외부링크) 화면으로 이동  - OVERFLOW_ACTION_TYPE_HIDE: post 기능과 같으나 이후 아티클 숨기기  - OVERFLOW_ACTION_TYPE_HIDE_WITH_TARGET: target 기능과 같으나 이후 아티클 숨기기  - OVERFLOW_ACTION_TYPE_DO_NOTHING: 아무 일도 하지 않음. OverflowStyleType.title, OverflowStyleType.message를 위한 타입
 * @export
 * @enum {string}
 */

export const V1OverflowActionType = {
    UNSPECIFIED: 'OVERFLOW_ACTION_TYPE_UNSPECIFIED',
    POST: 'OVERFLOW_ACTION_TYPE_POST',
    TARGET: 'OVERFLOW_ACTION_TYPE_TARGET',
    HIDE: 'OVERFLOW_ACTION_TYPE_HIDE',
    HIDE_WITH_TARGET: 'OVERFLOW_ACTION_TYPE_HIDE_WITH_TARGET',
    DO_NOTHING: 'OVERFLOW_ACTION_TYPE_DO_NOTHING'
} as const;

export type V1OverflowActionType = typeof V1OverflowActionType[keyof typeof V1OverflowActionType];



