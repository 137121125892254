/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/businesspost/v4/businesspost.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V4SearchSort = {
    UNSPECIFIED: 'SEARCH_SORT_UNSPECIFIED',
    RELEVANT: 'SEARCH_SORT_RELEVANT',
    RECENT: 'SEARCH_SORT_RECENT'
} as const;

export type V4SearchSort = typeof V4SearchSort[keyof typeof V4SearchSort];



